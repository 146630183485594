import React, { useEffect, useState } from "react"
import { Offline, Online } from "react-detect-offline"

const Test = () => {
  return (
    <>
      <Offline>this is offline</Offline>
      <Online>this is online</Online>
    </>
  )
}

export default Test
